import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { QA } from '@hozana/tests/constants'

import { Button } from 'elements/button/Button'
import { Cell } from 'elements/layout/Cell'
import { Row } from 'elements/layout/Row'
import { Link } from 'elements/text/Link'
import { P } from 'elements/text/P'
import { Text } from 'elements/text/Text'

import { acceptCookies } from 'app/actions'
import { hasAcceptedCookies as hasAcceptedCookiesSelector } from 'app/selectors'
import { FooterBanner } from 'app/structure/FooterBanner'
import { PAGE } from 'routes/constants'

import { getCookieBarOpened } from './functions'

export const CookieManager: React.FC = () => {
  const dispatch = useDispatch()
  const [isCookieBarOpened, setIsCookieBarOpened] = useState(false)
  const hasAcceptedCookies = useSelector((state) => hasAcceptedCookiesSelector(state))

  // This useEffect must be used instead of useState(getCookieBarOpened) otherwise it could break hydration
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/
  useEffect(() => {
    setIsCookieBarOpened(getCookieBarOpened(hasAcceptedCookies))
  }, [hasAcceptedCookies])

  const onAccept = () => {
    dispatch(acceptCookies())
  }

  if (!isCookieBarOpened) return null

  return (
    <FooterBanner name="cookies" zIndex="cookieBanner" p="6px">
      <Row gutter="1em">
        <Cell>
          <P fontSize="12px" color="darkBlue">
            trans:common:app.cookie.banner.intro.already
          </P>
          <P>
            <Link to={{ pathname: PAGE.STATIC, query: { pageSlug: 'cgu' } }} target="_blank" underline>
              <Text fontSize="12px">trans:common:app.cookie.banner.intro.more</Text>
            </Link>
          </P>
        </Cell>
        <Cell>
          <Button data-testid={QA.COMMON.BANNER.COOKIES.ACCEPT_BUTTON} size="small" onClick={onAccept}>
            trans:common:app.cookie.banner.intro.accept
          </Button>
        </Cell>
      </Row>
    </FooterBanner>
  )
}
