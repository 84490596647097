import dynamic from 'next/dynamic'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useApiData } from '@hozana/api/hooks/useApiData'
import { isBirthdayToday } from '@hozana/dates'
import { useEventListener } from '@hozana/hooks/useEventListener'
import { useInit } from '@hozana/hooks/useInit'
import { useIsHydrated } from '@hozana/hooks/useIsHydrated'
import { useIsNative } from '@hozana/hooks/useIsNative'
import { useDispatch } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { getFirstQueryValue, removeQueryValuesFromUrl } from '@hozana/router/functions'
import { getDisplayMode } from '@hozana/screen/functions/getDisplayMode'
import { cookie } from '@hozana/storage/cookies'
import { LOG_STATE } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'
import { hasLandingInfo, saveLandingInfo } from '@hozana/tracking/landingInfo'
import { getSessionInfo, saveSessionInfo } from '@hozana/tracking/sessionInfo'
import { saveSponsorInfos } from '@hozana/tracking/sponsor'

import { CONTENT_ID } from 'app/constants'
import { useCommonActions } from 'app/hooks/useCommonActions'
import { CookieManager } from 'app/managers/cookies/CookieManager'
import { COOKIES } from 'app/managers/cookies/constants'
import { FlashManager } from 'app/managers/flashs/FlashManager'
import { LangManager } from 'app/managers/languages/LangManager'
import { PopinsManager } from 'app/managers/popins/PopinsManager'
import { POPINS } from 'app/managers/popins/constants'
import type { TPopins } from 'app/managers/popins/types'
import { getStatisticsQuery } from 'app/queries'
import { Header } from 'app/structure/Header'
import { TransitionOverlay } from 'app/structure/TransitionOverlay'
import { PAGE } from 'routes/constants'

import { loadToken } from 'modules/auth/functions'
import { useIntentionBoost } from 'modules/intention/hooks/useIntentionBoost'
import { NOTIFICATION_TYPE } from 'modules/notification/constants'
import { useStatusManager } from 'modules/notification/hooks/useStatus'
import { useUser } from 'modules/user/hooks/useUser'

const PrayerPromptManager = dynamic(() =>
  import('app/managers/prompts/PrayerPromptManager').then((m) => m.PrayerPromptManager),
)
const AppPromptManager = dynamic(() => import('app/managers/prompts/AppPromptManager').then((m) => m.AppPromptManager))
const LinuxPopinManager = dynamic(() =>
  import('app/managers/prompts/LinuxPopinManager').then((m) => m.LinuxPopinManager),
)
const MailDeliveryIssuePromptManager = dynamic(() =>
  import('app/managers/prompts/MailDeliveryIssuePromptManager').then((m) => m.MailDeliveryIssuePromptManager),
)
const PushRequestManager = dynamic(() =>
  import('app/managers/prompts/PushRequestManager').then((m) => m.PushRequestManager),
)
const RatePromptManager = dynamic(() =>
  import('app/managers/prompts/RatePromptManager').then((m) => m.RatePromptManager),
)

/**
 * ### AppContainer ###
 *
 * This is the main container, for nearly all the pages.
 * It loads all the managers ([see in the app docs](../app.readme.md)) for example.
 */
export const AppContainer: React.FC = ({ children }) => {
  const router = useRouter()
  const {
    i18n: { language },
  } = useTranslation()
  const dispatch = useDispatch()
  const me = useUser()
  const isHydrated = useIsHydrated()

  const { pathname, query } = router

  const currentInfo = useMemo(
    () =>
      __CLIENT__
        ? {
            referrer: window.document.referrer,
            landingUrl: window.location.href,
            landingDate: new Date(),
          }
        : undefined,
    [],
  )

  useInit(() => {
    if (!hasLandingInfo()) {
      saveLandingInfo(currentInfo)
    }
  }, __CLIENT__)

  useEffect(() => {
    const storedSessionInfo = getSessionInfo()

    if (!storedSessionInfo) {
      saveSessionInfo(currentInfo)
    }
  }, [currentInfo, dispatch])

  useEffect(() => {
    // GTM data is stored in a cookie to be sent to GTM when the GTM script is loaded
    //
    // This is to avoid:
    // - setting up GTM in full-ssr-functions
    // - losing data:
    //   - we want to send GTM events on click on links in guide pages
    //   - since these pages are not hydrated, the clicks on such links reload the page
    //   - so either the data would not be sent to GTM
    //   - or we would need to delay the navigation
    //
    // This logic must be present in the new frontend root layout as well to handle links to migrated pages
    //
    // #gtmData

    const gtmData = cookie.load(COOKIES.gtmData)

    if (gtmData) {
      GTM.push(gtmData)
      cookie.remove(COOKIES.gtmData)
    }
  }, [])

  useApiData(() => ({ query: getStatisticsQuery() }), [])

  useEffect(() => {
    GTM.push({
      displayMode: getDisplayMode(),
      isOnline: LOG_STATE.ONLINE,
      lang: language,
    })

    if (loadToken()) {
      GTM.trackLogin()
    } else {
      GTM.trackLogout()
    }
  }, [language])

  useEventListener(
    'beforeinstallprompt',
    (e: BeforeInstallPromptEvent) => {
      GTM.trackEvent(GTM.EVENTS.PWA_HOMESCREEN_PROMPTED)
      e.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'dismissed') {
          GTM.trackEvent(GTM.EVENTS.PWA_HOMESCREEN_DISMISSED)
        } else {
          GTM.trackEvent(GTM.EVENTS.PWA_HOMESCREEN_ADDED)
        }
      })
    },
    undefined,
    !__CLIENT__,
  )

  useStatusManager()
  useIntentionBoost()
  useCommonActions()

  // Save sponsor key (uik)
  useEffect(() => {
    if (!me.isLogged && query.uik) {
      saveSponsorInfos(getFirstQueryValue(query.uik))
    }
  }, [query.uik, me.isLogged])

  /* renderPage = () => {
    // If there is an error with one of the API query, it will be either :
    // - Passed as the statusCode prop in case it is detected in SSR
    // - Passed as globalApiErrorCode (via Redux) if it is detected with the SW
    const statusCode = this.props.statusCode || this.props.globalApiErrorCode
    if (statusCode >= 400) {
      return <ErrorPage statusCode={statusCode} />
    } else {
      return this.props.children
    }
  } */

  const isAppIOS = useIsNative()

  // For QA tests, we need to unactive some popins and managers
  let popinsToDisplay: (keyof TPopins)[] = [
    POPINS.LinuxDevPopin,
    POPINS.AppPromptPopin,
    POPINS.PushRequestPopin,
    POPINS.RatePromptPopin,
    POPINS.MailDeliveryIssuePromptPopin,
    POPINS.PrayerPromptPopin,
  ]
  let bannerToDisplay = ['CookieBanner', 'LangBanner']

  // Handle withPopin and withBanner url query
  const withPopin = getFirstQueryValue(query.withPopin) || cookie.load(COOKIES.withPopin) // use for popins
  const withBanner = getFirstQueryValue(query.withBanner) || cookie.load(COOKIES.withBanner) // use for banners
  if (query.withPopin || query.withBanner) {
    if (query.withPopin) {
      cookie.save(COOKIES.withPopin, withPopin)
    }
    if (query.withBanner) {
      cookie.save(COOKIES.withBanner, withBanner)
    }
    removeQueryValuesFromUrl(router, ['withPopin', 'withBanner'])
  }
  if (withPopin && withPopin !== 'true') {
    popinsToDisplay = withPopin === 'false' ? [] : (withPopin.split(',') as (keyof TPopins)[])
  }
  if (withBanner && withBanner !== 'true') {
    bannerToDisplay = withBanner === 'false' ? [] : withBanner.split(',')
  }

  const displayPrompts =
    pathname &&
    !(
      [
        PAGE.ERROR,
        PAGE.ERROR_404,
        PAGE.ERROR_500,
        PAGE.PUBLICATION_CREATE,
        PAGE.COMMUNITY_CREATE,
        PAGE.COMMUNITY_VIEW_LANDING,
        PAGE.CONNECT,
        PAGE.AUTH_SOCIAL,
        PAGE.WIZARD,
        PAGE.WIZARD_CHAPTER,
        PAGE.UPLOAD_AVATAR,
      ] as PAGE[]
    ).includes(pathname)

  /** Don't show Header on following pages */
  const displayHeader = !(
    [
      PAGE.AUTH_SOCIAL,
      PAGE.COMMUNITY_VIEW_LANDING,
      PAGE.HOME,
      PAGE.EVENT_MAP,
      PAGE.CONNECT,
      PAGE.WIZARD,
      PAGE.WIZARD_CHAPTER,
    ] as PAGE[]
  ).includes(pathname)

  /** Don't show CookieBanner on following pages */
  const displayCookieBanner = !([PAGE.COMMUNITY_VIEW_LANDING, PAGE.AUTH_SOCIAL] as PAGE[]).includes(pathname)

  // Blocks are in their own div with an ID so that SSR hydration doesn't render them in the wrong div
  return (
    <>
      <div id="header">{displayHeader && <Header />}</div>
      <div id="transitionOverlay">{displayHeader && <TransitionOverlay />}</div>
      <div id={CONTENT_ID}>{children}</div>
      <div id="blocks">
        <div id="popinManager">
          <PopinsManager />
        </div>
        <div id="flashManager">
          <FlashManager />
        </div>
        <div id="langManager">
          <LangManager />
        </div>
        {displayCookieBanner && isHydrated && bannerToDisplay.includes('CookieBanner') && (
          <div id="cookieManager">
            <CookieManager />
          </div>
        )}
        {/* Do not display, PushRequestPopin, AppPromptPopin or RatePromptPopin when I create a community or a publication */}
        {isHydrated && displayPrompts && (
          <>
            {popinsToDisplay.includes(POPINS.LinuxDevPopin) && (
              <div id={POPINS.LinuxDevPopin}>
                <LinuxPopinManager />
              </div>
            )}
            {popinsToDisplay.includes(POPINS.PushRequestPopin) && (
              <div id={POPINS.PushRequestPopin}>
                <PushRequestManager />
              </div>
            )}
            {popinsToDisplay.includes(POPINS.AppPromptPopin) && (
              <div id={POPINS.AppPromptPopin}>
                <AppPromptManager />
              </div>
            )}
            {popinsToDisplay.includes(POPINS.PrayerPromptPopin) && (
              <div id={POPINS.PrayerPromptPopin}>
                <PrayerPromptManager
                  notificationType={NOTIFICATION_TYPE.NEW_PRAYER_ON_ARRIVAL}
                  cookieName={COOKIES.arrivalPrayerPrompt}
                />
                {me.birthdate && isBirthdayToday(me.birthdate) && (
                  <PrayerPromptManager
                    notificationType={NOTIFICATION_TYPE.NEW_PRAYER_ON_BIRTHDAY}
                    cookieName={COOKIES.birthdayPrayerPrompt}
                  />
                )}
                {!!me.intention && (
                  <PrayerPromptManager
                    notificationType={NOTIFICATION_TYPE.NEW_PRAYER_ON_INTENTION}
                    cookieName={COOKIES.prayerPromptAndBubble}
                  />
                )}
              </div>
            )}
            {popinsToDisplay.includes(POPINS.RatePromptPopin) && isAppIOS && (
              <div id={POPINS.RatePromptPopin}>
                <RatePromptManager />
              </div>
            )}
            {popinsToDisplay.includes(POPINS.MailDeliveryIssuePromptPopin) && (
              <div id={POPINS.MailDeliveryIssuePromptPopin}>
                <MailDeliveryIssuePromptManager />
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
