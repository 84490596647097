import { QUERY_ACTION } from 'app/constants'

import { TUseQueryCallback, TUseQueryOptions, useQuery } from './useQuery'

/**
 * Check if the the url query contains a key 'action' with the actionKey value.
 * If it does, call the callback with the query as parameter, then remove the action from the url query.
 *
 * @param callback Callback that will be executed if the query match the actionKey
 * @param dependencies Callback dependencies array
 * @param actionKey The action value
 * @param options (optional)
 * @param options.disable (optional) Don't do anything while disabled is true
 * @param options.queriesToRemove (optional) Some query keys to remove
 */
export const useAction = (
  callback: TUseQueryCallback,
  dependencies: any[],
  actionKey: QUERY_ACTION | undefined,
  options?: TUseQueryOptions,
  // eslint-disable-next-line react-hooks/exhaustive-deps
): void => useQuery(callback, dependencies, actionKey ? { action: actionKey } : null, options)
