import { useState } from 'react'

/**
 * useInit will execute the init function only once on mount, before render, before any children function.
 * By default, run both on client and on server.
 * useInit(initFunction, isClient) will only run on client.
 * useInit(initFunction, isServer) will only run on server.
 *
 * @param initFunction Init function
 * @param execute Conditions execution of init function. If false, will prevent init function to be executed. Default to true.
 */
export const useInit = (initFunction: VoidFunction, execute = true) => {
  useState(execute ? initFunction : null)
}
