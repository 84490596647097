import React, { useEffect, useState } from 'react'

import { usePrevious } from '@hozana/hooks/usePrevious'
import { detectLangFromNavigator, getLocaleStatus, getNewLocaleUrlObject } from '@hozana/intl/functions'
import { useDispatch } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'
import { translateUrl } from '@hozana/router/functions'
import { APP_TYPE } from '@hozana/screen/constants'
import { getAppType } from '@hozana/screen/functions/getAppType'
import { cookie } from '@hozana/storage/cookies'
import { QA } from '@hozana/tests/constants'

import { Button } from 'elements/button/Button'

import { toggleLangBar } from 'app/actions'
import { QUERY_ACTION } from 'app/constants'
import { getLangCookie, setLangCookie } from 'app/functions'
import { COOKIES, COOKIES_CONFIG } from 'app/managers/cookies/constants'
import { FooterBanner } from 'app/structure/FooterBanner'
import { Locale } from 'config/types'
import { TLocale, UNTRANSLATABLE_PAGE_FALLBACKS } from 'i18n/constants'
import { PAGE } from 'routes/constants'

import { ACCORDION_SECTION_PARAM_NAME, SETTINGS_SECTION_ID } from 'modules/user/constants'
import { useUser } from 'modules/user/hooks/useUser'

const HOZANA_IN_LANG = {
  fr: 'Hozana en français',
  es: 'Hozana en español',
  en: 'Hozana in English',
  pt: 'Hozana em português',
  it: 'Hozana in italiano',
  pl: 'Hozana po polsku',
}

export const LangManager: React.FC = () => {
  const { pathname, query, locale, replace, reload } = useRouter() // #router.replace
  const me = useUser()
  const prevMe = usePrevious(me)
  const dispatch = useDispatch()

  const [suggestedLang, setSuggestedLang] = useState<TLocale | null>(null)

  // Update language cookie
  useEffect(() => {
    if (me.isLogged && !me.loading && me.lang && me.lang !== getLangCookie()) {
      setLangCookie(me.lang)
    }
  }, [me.isLogged, me.lang, me.loading])

  // Redirect from legacy locale domain prefixes (ex: en.hozana.org)
  useEffect(() => {
    const domainLang = window.location.hostname.split('.')[0] as TLocale
    if (['en', 'es', 'pt'].includes(domainLang)) {
      replace({ pathname, query }, undefined, { locale: domainLang })
    }
  }, [pathname, query, replace])

  // Redirect user to its language (Cf. middleware.ts that does it too on /prayer-space route load, on server side)
  useEffect(() => {
    const userLang = me.isLogged ? me.lang : getLangCookie() || detectLangFromNavigator()
    const userLangUrl = translateUrl(
      {
        pathname,
        query: {
          ...query,
          ...(pathname === PAGE.SETTINGS &&
            query[ACCORDION_SECTION_PARAM_NAME] === SETTINGS_SECTION_ID.LANG && {
              action: QUERY_ACTION.SHOW_FLASH,
              message: 'common:user.settings.applied',
            }),
        },
      },
      userLang,
      { format: 'string' },
    )
    // Only do something if the user language is different than the site language and if the current and target sites are active
    if (
      userLang &&
      userLang !== locale &&
      getLocaleStatus(locale as Locale) === 'active' &&
      getLocaleStatus(userLang as Locale) === 'active'
    ) {
      if (me.isLogged) {
        if (
          !(
            [
              // Avoid looping redirects (redirects from CommunityPage and PublicationPage getStaticProps)
              ...Object.keys(UNTRANSLATABLE_PAGE_FALLBACKS),
              // Once logged in, must not redirect to connect page before redirecting to the destination page
              PAGE.CONNECT,
              // Do not redirect on SocialAuthPage so that the connect flow is not broken
              PAGE.AUTH_SOCIAL,
            ] as PAGE[]
          ).includes(pathname)
        ) {
          // If user is logged and the route exists on his language, redirect him automatically
          setLangCookie(userLang)
          window.location.href = userLangUrl
        }
      } else if (getAppType() === APP_TYPE.ANDROID_TWA) {
        // If user is not logged and he is on the TWA, redirect to the user language
        // We must redirect automatically in that case because some users come directly from the Playstore
        // and are not french. In that case we don't have SEO issues.
        window.location.href = userLangUrl
      } else if (!cookie.load(COOKIES.hideBannerLang)) {
        // If we are not on a TWA, only show the lang banner unless he chose to hide it
        // We do not redirect automatically to avoid SEO issues
        setSuggestedLang(userLang)
        dispatch(toggleLangBar(true))
      }
    }
  }, [dispatch, locale, me, pathname, prevMe, query, reload, replace])

  const close = () => {
    cookie.save(COOKIES.hideBannerLang, true, COOKIES_CONFIG.ONE_YEAR)
    setSuggestedLang(null)
    dispatch(toggleLangBar(false))
  }

  const withBanner = cookie.load<string>(COOKIES.withBanner)
  if (!suggestedLang || (withBanner && withBanner !== 'true' && !withBanner.includes('LangBanner'))) {
    return null
  }

  return (
    <FooterBanner name="lang" zIndex="langBanner" onClose={close}>
      <Button
        data-testid={QA.COMMON.BANNER.LANG.CHOOSE_SUGGESTED_BUTTON}
        size="bigger"
        valueLink={{
          to: getNewLocaleUrlObject({ pathname, query }),
          locale: suggestedLang,
          reload: true,
        }}
        onClick={close}
      >
        {HOZANA_IN_LANG[suggestedLang]} !
      </Button>
    </FooterBanner>
  )
}
