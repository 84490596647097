import React from 'react'

import { QA } from '@hozana/tests/constants'

import { RoundButton } from 'elements/button/RoundButton'
import { AbsoluteDiv } from 'elements/layout/AbsoluteDiv'
import type { TDivProps } from 'elements/layout/Div'
import { FixedDiv } from 'elements/layout/FixedDiv'
import { Shadow } from 'elements/layout/Shadow'

import { ICON } from 'config/icons'

export type TFooterBannerProps = {
  onClose?: () => void
  name: 'cookies' | 'lang' | 'publication'
} & TDivProps

type TUppercaseName = Uppercase<TFooterBannerProps['name']>

export const FooterBanner: React.FC<TFooterBannerProps> = ({
  children,
  onClose,
  name,
  zIndex,
  p = '1em',
  ...otherProps
}) => (
  <FixedDiv
    bottom
    left
    right
    zIndex={zIndex}
    noPrint
    data-testid={QA.COMMON.BANNER[name.toUpperCase() as TUppercaseName].WRAPPER}
  >
    <Shadow depth="10px" x="0" y="0" bg="background" align="middleCenter" p={p} {...otherProps}>
      {children}
    </Shadow>
    {onClose && (
      <AbsoluteDiv right top bottom align="middleCenter" p={p}>
        <RoundButton
          data-testid={QA.COMMON.BANNER[name.toUpperCase() as TUppercaseName].CLOSE_BUTTON}
          onClick={onClose}
          icon={ICON.CLOSE}
          color="black50"
          label="trans:common:word.close"
          ariaLabel="trans:common:word.close"
        />
      </AbsoluteDiv>
    )}
  </FixedDiv>
)
