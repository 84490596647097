import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchApi } from '@hozana/api/fetchApi'
import { addLangMeta } from '@hozana/api/functions'
import { useInterval } from '@hozana/hooks/useInterval'
import { useDispatch } from '@hozana/redux/hooks'

import { useMyIntention } from 'modules/intention/hooks/useMyIntention'
import { boostIntentionMutation } from 'modules/intention/queries'

/**
 * ### useIntentionBoost ###
 *
 * Handles intentions boosting by pinging the API every 5 minutes when necessary.
 */
export const useIntentionBoost = (): void => {
  const dispatch = useDispatch()
  const {
    i18n: { language },
  } = useTranslation()

  // intervalStarted keeps track of whether a boosting session is in progress, to avoid boosting the same intention on every render
  const [intervalStarted, setIntervalStarted] = useState(false)
  // boostCount keeps track of the amount of boosts given to the current intention
  const [boostCount, setBoostCount] = useState(0)

  const myIntention = useMyIntention()
  const shouldBoost = myIntention && dayjs().isBefore(myIntention.publishedUntil)

  // Trigger a reset of the boosting session when the current intention ID changed
  useEffect(() => {
    if (myIntention?.id) {
      setBoostCount(0)
      setIntervalStarted(false)
    }
  }, [myIntention?.id])

  /** Date from which we can start boosting the current intention (creationDate + 1 hour) */
  const boostStartDate = useMemo(() => {
    if (!myIntention) {
      return null
    }

    const startDate = new Date(myIntention.createdAt)
    startDate.setHours(startDate.getHours() + 1)

    return startDate
  }, [myIntention])

  const boost = useCallback(() => {
    setIntervalStarted(true)
    setBoostCount((prevBoostCount) => prevBoostCount + 1)
    dispatch(fetchApi(addLangMeta(boostIntentionMutation(), language)))
  }, [dispatch, language])

  useEffect(() => {
    // If we have access to the current intention, we already passed boostStartDate date and we are not currently in a boosting session
    // it means we can trigger a boost immediatly on the current intention
    if (shouldBoost && !intervalStarted && boostStartDate <= new Date()) {
      boost()
    }
  }, [boost, boostStartDate, shouldBoost, intervalStarted])

  // Trigger a boost every 5 minutes, with a maximum of 3 boosts (15 minutes of boost in total)
  useInterval(boost, 5 * 60 * 1000, !shouldBoost || boostStartDate > new Date() || boostCount >= 3)
}
