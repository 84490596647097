import { APP_TYPE } from '@hozana/screen/constants'
import { getAppType } from '@hozana/screen/functions/getAppType'
import { DIMENSIONS } from '@hozana/tracking/constants'

export const getDisplayMode = () => {
  switch (getAppType()) {
    case APP_TYPE.ANDROID_TWA:
      return DIMENSIONS.DISPLAY_MODE.TWA_ANDROID
    case APP_TYPE.IOS_NATIVE:
      return DIMENSIONS.DISPLAY_MODE.APP_IOS
    case APP_TYPE.ANDROID_PWA:
      return DIMENSIONS.DISPLAY_MODE.PWA_ANDROID
    case APP_TYPE.IOS_PWA:
      return DIMENSIONS.DISPLAY_MODE.PWA_IOS
    case APP_TYPE.BROWSER_DESKTOP:
    case APP_TYPE.BROWSER_MOBILE:
      return DIMENSIONS.DISPLAY_MODE.BROWSER
    default:
      return null
  }
}
